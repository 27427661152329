import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/logo.png'; // Add your logo image to the assets folder

const Navbar = (props) => {
  return (
    <div className="Navbar">
    <Link to="/" className="logo-link">
        <img src={logo} alt="Company Logo" className="navbar-logo" />
      </Link>
      <nav className="App-nav">
        <Link to="/privacy-policy" className="App-nav-link">Privacy Policy</Link>
        <Link to="/about-us" className="App-nav-link">About Us</Link>
    </nav>
    </div>
  );
}

export default Navbar;
