import React from 'react';
import { useSpring, animated } from '@react-spring/web';
import { useInView } from 'react-intersection-observer';

const Contact = () => {
  const [ref, inView] = useInView({ triggerOnce: true });
  const fadeIn = useSpring({
    opacity: inView ? 1 : 0,
    transform: inView ? 'translateY(0)' : 'translateY(20px)',
  });
  return (
    <animated.section style={fadeIn} className="Contact" ref={ref}>
      <h2>Contact Us</h2>
      <p>For more information or inquiries, please contact us at info@keywordkoala.io.</p>
    </animated.section>
  );
}

export default Contact;
