import React from 'react';
import { useSpring, animated } from '@react-spring/web';
import { useInView } from 'react-intersection-observer';

const Founders = () => {
  const [ref, inView] = useInView({ triggerOnce: true });
  const fadeIn = useSpring({
    opacity: inView ? 1 : 0,
    transform: inView ? 'translateY(0)' : 'translateY(20px)',
  });
  return (
    <animated.section style={fadeIn} className="Founders" ref={ref}>
      <h2>Meet the Team</h2>
      <p>Our team is composed of experienced professionals in the field of SEO and AI.</p>
      <div style={{flexDirection: "horizontal"}}>
      <div className="founder">
        <h3>Aneela Canty</h3>
        <p>Studied Data Science at Indiana University and has over 4+ years of work experience at early stage and VC funded AI startups in roles like customer success, marketing, business development, and engineering.</p>
      </div>
      <div className="founder">
        <h3>Barry Ng</h3>
        <p>Senior Software Engineer who studied Mathematics at Northwestern University.</p>
      </div>
      </div>
      </animated.section>
  );
}

export default Founders;
