import React from 'react';
import { useSpring, animated } from '@react-spring/web';
import { useInView } from 'react-intersection-observer';

const Introduction = () => {
    const [ref, inView] = useInView({ triggerOnce: true });
    const fadeIn = useSpring({
      opacity: inView ? 1 : 0,
      transform: inView ? 'translateY(0)' : 'translateY(20px)',
    });

  return (
        <animated.section style={fadeIn} className="Introduction" ref={ref}>
      <h2>About Our Software</h2>
      <p>
      At Keyword Koala, we're revolutionizing SEO with the power of AI. We equip businesses with the data-driven strategies, content creation tools, and free title optimization features they need to outrank competitors and dominate search rankings, driving organic traffic and propelling business growth.
      </p>
      <br></br>
      <p>
        Our AI-powered SEO software leverages the latest technology to provide you with the best
        insights and tools to optimize your search engine performance. Our intuitive interface
        and powerful algorithms ensure you stay ahead of the competition.

      </p>
      </animated.section>
  );
}

export default Introduction;
