import React from 'react';
import '../styles/PrivacyPolicy.css';


const PrivacyPolicy = () => {
  return (
    <section className="PrivacyPolicy">
      <h2>Privacy Policy</h2>
      <p>This Privacy Policy describes how “Keyword Koala” (“we,” “our,” or “us”) collects, uses, and protects any information you may provide while using the “Keyword Koala” app (the “App”). We are committed to ensuring that your privacy is protected.</p>
      <h4>
      Information We Do Not Collect
      </h4>
      <p>
      We do not collect or require any personal information, such as your name, email address, or contact information, to use the App. We respect your privacy and are committed to safeguarding your data.
      </p>
      <h4>
      Third-Party Services
      </h4>
      <p>
      Please note that this Privacy Policy does not apply to any third-party services or websites that you may access through the App. We recommend reviewing the privacy policies of any third-party services you interact with.
      </p>
      <h4>
      Analytics
      </h4>
      <p>
      We do not use any analytics services to collect information about how you use the App. We do not track your usage patterns or gather any data related to your interactions with the App.
      </p>
      <h4>
      Contact Us
      </h4>
      <p>
      If you have any questions or concerns about this Privacy Policy or our practices, please contact us at info@keywordkoala.io
      </p>
      <h4>
      Changes to This Privacy Policy
      </h4>
      <p>
      We may update this Privacy Policy from time to time to reflect changes to our practices or for other operational, legal, or regulatory reasons. We encourage you to review this Privacy Policy periodically to stay informed about how we are protecting your privacy.
      </p>
      <h4>
      Effective Date
      </h4>
      <p>
      This Privacy Policy was last updated on 6/20/2024.

        By using the “Keyword Koala” App, you agree to the terms and conditions of this Privacy Policy. If you do not agree with the terms of this Privacy Policy, please do not use the App.
      </p>
    </section>
  );
}

export default PrivacyPolicy;
