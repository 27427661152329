import { Route, Routes } from 'react-router-dom';
import React, { useState } from 'react';
import './App.css';
import Header from './components/Header';
import Founders from './components/Founders';
import OverlayForm from './components/OverlayForm';
import PrivacyPolicy from './components/PrivacyPolicy';
import Navbar from './components/Navbar';

function App() {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  return (
    <div className="App">
      <div className="app">
        <Navbar />
        <Routes>
        <Route path="/"  element={<Header openFunc={openModal}/>} />
        <Route path="/privacy-policy"  element={<PrivacyPolicy/>} />
        <Route path="/about-us"  element={<Founders/>} />
        </Routes>
        </div>
      <OverlayForm isOpen={modalIsOpen} onRequestClose={closeModal} />
    </div>
  );
}

export default App;
