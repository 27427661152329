import React from 'react';
import Contact from './Contact';
import Introduction from './Introduction';

const Header = (props) => {
  return (
    <>
    <header className="App-header">
      <div className="App-header-content">
      <h1>AI-Powered SEO SaaS</h1>
      <p>Your ultimate solution for search engine optimization.</p>
      <button onClick={() => props.openFunc()} className="interest-button">Join Early Access Waitlist</button>
      </div>
    </header>
    <Introduction />
    <Contact />
    </>
  );
}

export default Header;
