import React, { useState } from 'react';
import Modal from 'react-modal';
import axios from 'axios';


Modal.setAppElement('#root');

const OverlayForm = ({ isOpen, onRequestClose }) => {
    const [email, setEmail] = useState('');
    const [industry, setIndustry] = useState('');
    const [otherIndustry, setOtherIndustry] = useState('');
    const [role, setRole] = useState('');
    const [companySize, setCompanySize] = useState('');
    const [interests, setInterests] = useState({
      keywordResearch: false,
      competitorAnalysis: false,
      backlinkTracking: false,
      siteAudits: false,
      other: false,
    });
    const [comments, setComments] = useState('');

    const handleSubmit = async (event) => {
        event.preventDefault()
        const response = await axios.post('https://s9bq6b7e3e.execute-api.us-east-1.amazonaws.com/prod', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: {         
            email,
            industry: industry === 'other' ? otherIndustry : industry,
            role,
            companySize,
            interests,
            comments,
        },
        })
        if (response.status === 200) {
          alert('Thank you for signing up! We will be in touch.')
          setEmail('')
          setIndustry('');
          setOtherIndustry('');
          setRole('');
          setCompanySize('');
          setInterests({
            keywordResearch: false,
            competitorAnalysis: false,
            backlinkTracking: false,
            siteAudits: false,
          });
          setComments('');
          onRequestClose()
        } else {
          alert('There was an issue with your submission. Please try again.')
        }
      }

      const handleInterestChange = (event) => {
        const { name, checked } = event.target;
        setInterests((prevInterests) => ({
          ...prevInterests,
          [name]: checked,
        }));
      };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Sign Up Form"
      className="Modal"
      overlayClassName="Overlay"
    >
      <h2>Sign Up for Updates</h2>
      <form onSubmit={handleSubmit} className="form">
        <label htmlFor="email" className="form-label">Email:</label>
        <input
          type="email"
          id="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
          className="form-input"
        />
        
        <label htmlFor="industry" className="form-label">Industry:</label>
        <select
          id="industry"
          value={industry}
          onChange={(e) => setIndustry(e.target.value)}
          className="form-input"
        >
          <option value="">Select your industry</option>
          <option value="ecommerce">Ecommerce</option>
          <option value="socialMedia">Social Media Influencer</option>
          <option value="finance">Finance</option>
          <option value="accounting">Accounting</option>
          <option value="tech">Tech</option>
          <option value="other">Other</option>
        </select>
        {industry === 'other' && (
          <input
            type="text"
            id="otherIndustry"
            placeholder="Please specify"
            value={otherIndustry}
            onChange={(e) => setOtherIndustry(e.target.value)}
            className="form-input"
          />
        )}

        <label htmlFor="role" className="form-label">Role:</label>
        <select
          id="role"
          value={role}
          onChange={(e) => setRole(e.target.value)}
          className="form-input"
        >
          <option value="">Select your role</option>
          <option value="employee">Employee</option>
          <option value="businessOwner">Business Owner</option>
          <option value="freelancer">Freelancer</option>
        </select>

        <label htmlFor="companySize" className="form-label">Company Size:</label>
        <select
          id="companySize"
          value={companySize}
          onChange={(e) => setCompanySize(e.target.value)}
          className="form-input"
        >
          <option value="">Select company size</option>
          <option value="1-10">1-10 employees</option>
          <option value="11-50">11-50 employees</option>
          <option value="51-200">51-200 employees</option>
          <option value="201-500">201-500 employees</option>
          <option value="500+">500+ employees</option>
        </select>

        <label className="form-label">What features are you interested in?</label>
        <div className="form-checkbox-group">
          <label className="form-checkbox-label">
            <input
              type="checkbox"
              name="keywordResearch"
              checked={interests.keywordResearch}
              onChange={handleInterestChange}
            />
            Keyword Research
          </label>
          <label className="form-checkbox-label">
            <input
              type="checkbox"
              name="competitorAnalysis"
              checked={interests.competitorAnalysis}
              onChange={handleInterestChange}
            />
            Competitor Analysis
          </label>
          <label className="form-checkbox-label">
            <input
              type="checkbox"
              name="backlinkTracking"
              checked={interests.backlinkTracking}
              onChange={handleInterestChange}
            />
            Backlink Tracking
          </label>
          <label className="form-checkbox-label">
            <input
              type="checkbox"
              name="siteAudits"
              checked={interests.siteAudits}
              onChange={handleInterestChange}
            />
            Site Audits
          </label>
          <label className="form-checkbox-label">
            <input
              type="checkbox"
              name="other"
              checked={interests.other}
              onChange={handleInterestChange}
            />
            Other (please specify in comments)
          </label>
        </div>

        <label htmlFor="comments" className="form-label">Comments:</label>
        <textarea
          id="comments"
          value={comments}
          onChange={(e) => setComments(e.target.value)}
          className="form-textarea"
        ></textarea>

        <button type="submit" className="form-button">Submit</button>
        <button type="button" onClick={onRequestClose} className="form-button close-button">Close</button>
      </form>
    </Modal>
  );
}

export default OverlayForm;
